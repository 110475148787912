/**
 * @typedef AudienceCampaignDurationType
 * @property {string} startDate
 * @property {string} endDate
 */

/** @type AudienceCampaignDurationType */
export default class AudienceCampaignDuration {
    constructor(durationJson) {
        this.startDate = durationJson.start_date
        this.endDate = durationJson.end_date
    }
}