<template>
  <div>
    <label class="add_bv_desc">{{ $t('brandVoices.languages') }}</label>
    <div class="button_switch_panel-container button_switch_panel-container__brand_voice" style="text-align: center;">
      <button @click="switchTab = 'add_link'" class="button_switch_panel button_switch_panel__bv"
        :class="switchTab == 'add_link' ? 'button_switch_panel--selected button_switch_panel__bv--selected' : ''">Add
        Link</button>
      <button @click="switchTab = 'add_text'" class="button_switch_panel button_switch_panel__bv"
        :class="switchTab == 'add_text' ? 'button_switch_panel--selected button_switch_panel__bv--selected' : ''">Add
        Text</button>
      <button @click="switchTab = 'add_file'" class="button_switch_panel button_switch_panel__bv"
        :class="switchTab == 'add_file' ? 'button_switch_panel--selected button_switch_panel__bv--selected' : ''">Add
        File</button>

    </div>
    <div v-show="switchTab == 'add_link'" class="col-md-12">
      <AddBrandVoiceByLink @submit="analizeText" />
    </div>
    <div v-show="switchTab == 'add_text'" class="col-md-12">
      <AddBrandVoiceByText @submit="analizeText" />
    </div>
    <div v-show="switchTab == 'add_file'" class="col-md-12">
      <AddBrandVoiceByFile @submit="analizeText" />
    </div>
  </div>
</template>

<script>
import AddBrandVoiceByText from './AddBrandVoiceByText.vue';
import AddBrandVoiceByFile from './AddBrandVoiceByFile.vue';
import AddBrandVoiceByLink from './AddBrandVoiceByLink.vue';

export default {
  components: {
    AddBrandVoiceByText,
    AddBrandVoiceByLink,
    AddBrandVoiceByFile,
  },

  data() {
    return {
      switchTab: 'add_link',
      addBrandVoiceComponentKey: 0
    };
  },
  methods: {

    async analizeText(form) {
      this.$preloader.fadeIn();
      await this.$store.dispatch('analizeText', form);
      this.$preloader.fadeOut();

      this.$emit('nextStep');
    }
  },
};
</script>
