/**
 * @typedef AudienceCampaignClientType
 * @property {Number} id
 * @property {string} name
 */

/** @type AudienceCampaignClientType */
export default class AudienceCampaignClient {
    constructor(clientJson) {
        this.id = clientJson.id
        this.name = clientJson.name
    }
}