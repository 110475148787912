<template>
  <ValidationObserver v-slot="{ handleSubmit }">

    <form class="brand_voice_form" @submit.prevent="handleSubmit(onSubmit)">
      <div>

        <ValidationProvider rules="required">
          <base-input label="Brandvoice name" type="text" placeholder="Add brand voice name" v-model="form.name">
          </base-input>
        </ValidationProvider>
        <ValidationProvider rules="required">
          <base-textarea :setStyle="'min-height:300px'" :label="'Description'" type="textarea" placeholder=""
            v-model="form.guidelines">
          </base-textarea>
        </ValidationProvider>
        <div v-if="!hasWorkspace" class="d-flex justify-content-center align-items-center">
          <base-checkbox v-model="form.default">Set as default</base-checkbox>
        </div>

        <base-button native-type="submit" type="primary" class="btn-fill btn_submit_form w-100">
        {{ $t('generic.save') }}
        </base-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { Select, Option } from 'element-ui';
import { BaseTextarea } from 'src/components/index';
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapGetters } from 'vuex';

extend("required", required);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseTextarea,
  },
  props: {
    brandVoice: Object
  },
  data() {
    return {
      form: {
        name: "",
        guidelines: "",
        default: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      hasWorkspace: 'getHasWorkspace',
      analizedText: 'getAnalizedText',
    }),
  },
  methods: {
    onSubmit() {
      this.$emit('submit', { ...this.form });
    },
  },
  mounted() {
    // Update
    if (this.brandVoice) {
      this.form = { ...this.brandVoice };
      this.form.default = Boolean(this.form.default);    
    }
    else {
      this.form.guidelines = this.analizedText;
    }
  }
};
</script>
