<template>
  <div>
    <div class="row">
      <div v-if="!updatingPageUrl" class="col-12 batch_order-toggle mb-2">
        <div class="batch_order-button_list">
          <button
            :class="['fs-12', form.type == 'single' ? 'active' : '']"
            @click="form.type = 'single'"
          >
            Single URL
          </button>
          <button
            :class="['fs-12', form.type == 'multiple' ? 'active' : '']"
            @click="form.type = 'multiple'"
          >
            Multiple URLs
          </button>
        </div>
      </div>
      <div class="col-12 mb-3">
        <p v-if="form.type == 'single'">Link new URLs with this workspace.</p>
        <p v-if="form.type == 'multiple'">Link new URLs with this workspace.</p>
      </div>
    </div>
    <ValidationObserver v-slot="{ handleSubmit, valid }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <span v-if="loading" class="loader"></span>
        <div class="row">
          <div :class="[form.type == 'single' ? 'col-6 pr-2' : 'col-12']">
            <BrandVoicesSelect
              @change="form.brand_voice_id = $event"
              :required="true"
              :hide-create="true"
              :hide-tov="true"
              :hide-inactive-bv="false"
              :brand-voice-selected="form.brand_voice_id"
            />
          </div>

          <ValidationProvider
            v-if="form.type == 'single'"
            rules="required"
            class="col-6 pl-2"
          >
            <base-input
              :label="'Page Title'"
              type="text"
              :placeholder="'Enter a page title'"
              v-model="form.page_title"
            >
            </base-input>
          </ValidationProvider>
        </div>

        <div v-if="form.type == 'single'" class="row">
          <ValidationProvider rules="required" class="col-6 pr-2">
            <base-input
              :label="'Page Name'"
              type="text"
              :placeholder="'Enter a name for the page'"
              v-model="form.page_name"
            >
            </base-input>
          </ValidationProvider>

          <ValidationProvider rules="required" class="col-6 pl-2">
            <base-input
              :label="'URL'"
              type="url"
              :placeholder="'Enter page URL'"
              v-model="form.url"
            >
            </base-input>
          </ValidationProvider>

          <ValidationProvider rules="required" class="col-12">
            <base-input
              :label="'Page Description'"
              type="text"
              :placeholder="'Write a brief description of the page'"
              v-model="form.page_description"
            >
            </base-input>
          </ValidationProvider>

          <ValidationProvider rules="required" class="col-6 pr-2">
            <base-input
              :label="'Page Type'"
              type="text"
              :placeholder="'e.g. <page type example> '"
              v-model="form.page_type"
            >
            </base-input>
          </ValidationProvider>

          <ValidationProvider rules="required" class="col-6 pl-2">
            <base-input
              :label="'Page ID'"
              type="text"
              :placeholder="'e.g. <page id example> '"
              v-model="form.page_id"
            >
            </base-input>
          </ValidationProvider>
        </div>

        <!-- File uplader -->
        <div v-if="form.type == 'multiple'" class="row mt-1">
          <div class="col-12">
            <div class="fileInput-label">
              <label>{{ $t("batch.uploadFile") }}</label>
              <a :href="csvFilePath" download>
                <i class="fa-solid fa-cloud-arrow-down"></i>
                <span>{{ $t("batch.downloadSampleFile") }}</span>
              </a>
            </div>
            <FileUpload
              @deleteFile="deleteFile"
              @addFile="onFileChange"
              :fileList="form.files"
              :fileType="'.csv'"
            />
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-6 pr-2">
            <base-button
              @click.prevent="hideForm"
              type="primary"
              class="btn-fill btn_add_link px-3 mt-0 w-100"
            >
              Cancel
            </base-button>
          </div>
          <div class="col-6 pl-2">
            <base-button
              native-type="submit"
              type="primary"
              class="btn-fill btn_submit_form px-3 mt-0 w-100"
              :disabled="
                !valid ||
                (form.type == 'multiple' && form.files == null) ||
                loading
              "
            >
              {{ updatingPageUrl ? "Edit URL" : "Add URLs" }}
            </base-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { Form, Select, Option } from "element-ui";
import BrandVoicesSelect from "src/pages/Tools/ToolsComponent/BrandVoicesSelect.vue";
import FileUpload from "@/components/FileUpload.vue";

export default {
  components: {
    [Form.name]: Form,
    [Select.name]: Select,
    [Option.name]: Option,
    BrandVoicesSelect,
    FileUpload,
  },
  props: {
    updatingPageUrl: Object,
  },
  data() {
    return {
      loading: false,
      form: {
        type: "single",
        page_title: "",
        page_description: "",
        page_name: "",
        url: "",
        page_type: "",
        page_id: "",
        files: null,
        brand_voice_id: null,
      },
      csvFilePath: process.env.BASE_URL + "csv/" + "page_url" + ".csv",
    };
  },

  methods: {
    hideForm() {
      this.$emit("hideForm");
    },

    async onSubmit() {
      this.loading = true;

      if (this.form.type == "multiple") {
        delete this.form.page_title;
        delete this.form.page_description;
        delete this.form.page_name;
        delete this.form.url;
        delete this.form.page_type;
        delete this.form.page_id;
      } else {
        delete this.form.files;
      }

      if (this.updatingPageUrl) {
        await this.$store
          .dispatch("updatePageUrl", this.form)
          .then(() => {
            this.$emit("hideForm", true);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        await this.$store
          .dispatch("createPageUrl", { ...this.form })
          .then((response) => {
            this.$emit("hideForm", true);
          })
          .finally(() => {
            this.loading = false;
          });
      }

      this.$emit("urlChanged", this.form);
    },

    onFileChange(file) {
      this.form.files = file;
    },

    deleteFile() {
      this.form.files = null;
    },
  },

  mounted() {
    if (this.updatingPageUrl) {
      this.form = { ...this.updatingPageUrl };
      this.form.type = "single";
    }
  },
};
</script>
