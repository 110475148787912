export { formatDate } from "@/util/date";

export const formatStatus = function (status) {
    switch (status) {
        case 'NEW':
            return 'New'
        case 'ONGOING':
            return 'Ongoing'
        case 'STOPPED':
            return 'Stopped'
        case 'COMPLETED':
            return 'Completed'
    }
}

export const formatCost = function(cost, currencyCode) {
    return cost + ' ' + currencyCode
}