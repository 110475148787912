<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <div>

        <ValidationProvider rules="required">
            <base-textarea :label="$t('brandVoices.addByText.enterText')" type="textarea"
              :placeholder="$t('brandVoices.addByText.writePaste')" v-model="form.text">
            </base-textarea>
        </ValidationProvider>

        <base-button native-type="submit" type="primary" class="btn-fill btn_submit_form w-100">
        {{ $t('brandVoices.addByText.analyzeText') }}
        </base-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { Select, Option } from 'element-ui';
import { BaseTextarea } from 'src/components/index';
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseTextarea,
  },
  data() {
    return {
      form: {
        text: '',
      },
    };
  },
  methods: {
    onSubmit() {
      this.$emit('submit', { ...this.form });
    },
  }
};
</script>
