<template>
  <div class="row" v-if="!this.$store.getters.getUserExtraInfo?.hide_generic_task_assignment ?? false">
    <card class="col-12 card-stats mb-4 ml-3" :show-footer-line="false">
        <div v-for="item in taskInOrder" :key="item.id">
          <div class="row">
            <h3 class="card-title my-0"> {{ order.title }}</h3>
          </div>
          <div class="row">
            <span class="title-task">Task {{ item.title }}</span>
          </div>
          <div class="row" v-if="getUser.id != item.assignee_id && item.order_status != 'approved' && item.order_status != 'rejected'">
            <label class="pill pill--in_progress mt-2 w-50">Send for approval {{ item.complete_date }}</label>
          </div>
          <div class="row">
            <div class="reject-note mt-3 mb-2 w-100" v-if="item.order_status === 'rejected'">
              <label class="date-comment">Rejected on {{ item.rejected_date}}</label>
              <label class="text-comment"> Comment</label>
              <label class="text-reject">{{ item.rejected_note}}</label>
            </div>
          </div>
          <div class="row" v-if="item.order_status === 'approved'">
            <label class="pill pill--complete mt-2 w-50">Approved on {{ item.approve_date}}</label>
          </div>
          <div class="row mt-3">
            <label>Executor</label>
          </div>
          <div class="row mb-4">
            <input class="w-100" type="text" disabled :placeholder="item.name">
          </div>
          <div v-if="getUser.id != item.assignee_id && item.order_status != 'approved' && item.order_status != 'rejected'" class="row">
            <span>Documents in this task requires approval</span>
          </div>
          <div v-if="getUser.id != item.assignee_id && item.order_status != 'approved' && item.order_status != 'rejected'" class="row mt-2 flex-row">
            <div class="flex-grow-1">
              <button @click="rejectStatus()" class="btn btn-fill btn_add_link mb-2 mr-2 regenerate-btn btn-primary w-100">Reject</button>
            </div>
            <div class="flex-grow-1">
              <button @click="approveStatus()" class="btn mb-2 ml-2 w-100">Approve</button>
            </div>
        </div>
      </div>
      <el-dialog
      :title="'Why are you rejecting this task?'"
      :visible.sync="dialog.dialogRejectVisible"
      class="dialog-sm">
      <ValidationObserver>
        <form class="brand_voice_form" @submit.prevent="rejectedStatus(onSubmit)">
          <div class="mt-4">
            <span class="paragraph">
              Provide feedback on this task so that your team can resolve any issues - the more detail you provide, the easier this will be.
              You will receive an email when they resubmit this task for your approval.
            </span>
          </div>
          <div class="mt-4">
            <base-textarea :setStyle="'min-height:100px'" type="textarea" placeholder="Add your feedback here"
              v-model="form.textarea">
              </base-textarea>
          </div>
          <div class="row mt-4 flex-row ml-0">
            <div class="flex-grow-1">
                <button @click="dialog.dialogRejectVisible = false" class="btn btn-fill btn_add_link mb-2 regenerate-btn btn-primary w-100">Cancel</button>
              </div>
              <div class="flex-grow-1">
                <button native-type="submit" class="btn mb-2 ml-2">Reject with feedback</button>
              </div>
          </div>
        </form>
      </ValidationObserver>
      </el-dialog>
    </card>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import Card from "../../components/Cards/Card.vue";
import { Dialog } from "element-ui";
import { documentApproved } from "@/util/utils";
import { documentRejected } from "@/util/utils";
import { BaseTextarea } from 'src/components/index';

export default {
  name: "TaskAssignment",
  components: {
    [Dialog.name]: Dialog,
    BaseTextarea
  },
  data() {
    return {
      taskOrders: [],
      taskAssegnee: [],
      dialog: {
        dialogRejectVisible: false,
      },
      form: {
        task_id: "",
        order_status: "",
        textarea: "",
        rejected_date: "",
      },
    };
  },
  computed: {
    ...mapGetters("taskAssignmentGeneric", [
      "getTaskList",
      "getPeopleInWorkspace",
    ]),
    ...mapGetters(["getUser"]),
    ...mapGetters({
      order: "getOrder",
    }),
    ...mapGetters({ userExtraInfo: "getUserExtraInfo",}),
    ...mapGetters(["selectedWorkspace", "getUser", "getWorkspaces"]),

    taskInOrder() {
      let taskInOrder = this.taskOrders;
        return taskInOrder.filter(
          (task) => task.order_detail_id === this.order.order_id
        );
    }
  },
  methods: {
    pillClass(status) {
      switch (status) {
        case 'new':
          return 'pill--new';
        case 'in-progress':
          return 'pill--in_progress';
        case 'approved':
          return 'pill--complete';
        case 'in-review':
          return 'pill--review';
        default:
          return 'pill';
      }
    },
    onSubmit() {
      this.$emit('submit', { ...this.form });
    },
    async rejectedStatus() {

      try {
        let selectedTask = this.taskInOrder.find(({task_id}) => task_id);

        await this.$axios.post(`/features/tasks/order/${selectedTask.task_id}/status`,
          {
            order_status: "rejected",
            task_id: selectedTask.task_id,
            order_detail_id: this.order.order_id,
            rejected_note: this.form.textarea,
            rejected_date: new Date()
          },
        );
        this.dialog.dialogRejectVisible = false;
        this.$preloader.fadeIn();
        this.$preloader.fadeOut();
        documentRejected("documentRejected");
      } catch (error) {
        console.error(error);
      }
    },
    async getTaskOrder() {
      try {
        const response = await this.$axios.get(`/features/tasks/order`, {
        });
        this.taskOrders = response.data;
      } catch (error) {
        console.error('Error fetching task order:', error);
      }
    },
    async approveStatus() {
      try {
        let selectedTask = this.taskInOrder.find(({task_id}) => task_id);

        await this.$axios.post(`/features/tasks/order/${selectedTask.task_id}/status`,
          {
            order_status: "approved",
            task_id: selectedTask.task_id,
            order_detail_id: this.order.order_id,
            approve_date: new Date()
          },
        );
        this.$preloader.fadeIn();
        this.$preloader.fadeOut();
        documentApproved("documentApproved");
      } catch (error) {
        console.error(error);
      }
    },
    rejectStatus() {
      this.dialog.dialogRejectVisible = true;
    },
  },
  async mounted() {
    this.getTaskOrder();
  },
};
</script>

<style scoped>
.pill--new{
  background-color: #44A8B5 !important;
  color: white !important;
  padding: 6px;
  font-weight: 700;
  text-transform: uppercase;
  width: 58px;
  border-radius: 6px;
  margin: 0;
  padding: 0 8px;
}
.pill--review{
  background-color: #00BC7E !important;
  color: white !important;
  padding: 6px;
  font-weight: 700;
  text-transform: uppercase;
  width: 58px;
  border-radius: 6px;
  margin: 0;
  padding: 0 8px;
}
.pill--complete{
  background-color: #008157 !important;
  color: white !important;
  padding-top: 6px !important;
  font-weight: 700;
  text-transform: uppercase;
  width: 58px;
  border-radius: 6px;
  margin: 0;
  padding: 0 8px;
}
.pill--in_progress {
  padding-top: 6px !important;
  font-weight: 700;
  text-transform: uppercase;
  width: 58px;
  border-radius: 6px;
  margin: 0;
  padding: 0 8px;
}
.white-content a {
  color: var(--blu-004583, #004583) !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 18px !important;
}
.card-stats {
  border-radius: var(--Corner-radius, 6px);
  border: 1px solid var(--grey-eceef-1, #ECEEF1);
  background: var(--white-ffffff),
  }
  .title-task {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
  }
  ::placeholder {
    color: var(--blu-004583, #004583);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .reject-note {
    display: flex;
    padding: 14px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: var(--Corner-radius, 6px);
    background: var(--grey-f-2-f-4-f-6, #F2F4F6);
  }
  .text-reject {
    color: var(--blu-004583, #004583);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  .text-comment {
    color: var(--blu-004583, #004583);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .date-comment {
    color: var(--red-e-7122-c, #E7122C) !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
  }
</style>
