<template>
	<ValidationObserver v-slot="{ handleSubmit }">

		<form class="brand_voice_file_upload" @submit.prevent="handleSubmit(onSubmit)">
			<!-- <ValidationProvider rules="required"> -->
			<div>
				<label>Upload files</label>
			</div>
			<div class="brand_voice_file_upload__card">
				<input v-show="!form.files.length" name="upload" id="upload" type="file" accept=".pdf" @change="onFileChange">

				<div v-show="!form.files.length" class="brand_voice_file_upload__text">
					<i class="fa-solid fa-cloud-arrow-up"></i>
					<label>{{ $t('brandVoices.dragDrop.pdf') }}</label>
					<label>{{ $t('brandVoices.addByFile.or') }} <p class="brand_voice_file_upload__text__dinamic">{{ $t('brandVoices.addByFile.selectFiles') }}</p> {{ $t('brandVoices.addByFile.onYourPc') }}</label>
				</div>

				<ul v-show="form.files.length">
					<li v-for="file in form.files"><label class="mb-0">{{ file.name }}</label><i @click="onFileRemove(file.name)"
							class="fa-regular fa-xmark fs-16"></i></li>
				</ul>

				<el-progress v-show="showProgressBar" class="w-100 my-3" :percentage="progressBarPercentage" :color="customColor"></el-progress>

				<label class="brand_voice_file_upload__text--add-more" v-show="form.files.length <= 6 && form.files.length"
					for="upload">{{ $t('brandVoices.addByFile.addFiles') }}</label>
			</div>
			<!-- </ValidationProvider> -->

			<base-button native-type="submit" type="primary" class="btn-fill btn_submit_form w-100">
			{{ $t('brandVoices.addByFile.analyze') }}
			</base-button>

		</form>
	</ValidationObserver>
</template>

<script>
import { Select, Option, Progress } from 'element-ui';

export default {
	components: {
		[Select.name]: Select,
		[Option.name]: Option,
		[Progress.name]: Progress,
	},
	data() {
		return {
			form: {
				files: []
			},
			progressBarPercentage: 0,
			showProgressBar: false,
			progressBarDuration: 2000,
			customColor: '#FF9D00',
		};
	},
	methods: {
		onFileChange(e) {
			const file = e.srcElement.files[0] ?? null;

			if (file && this.form.files.length < 7) {
				this.form.files.push(file);

				// Fake progress bar
				this.showProgressBar = true;

				for (let index = 1; index <= 10; index++) {
					const duration = this.progressBarDuration * (index * 10) / 100;
					setTimeout(() => {
						this.progressBarPercentage = index * 10;
					}, duration);
				}

				setTimeout(() => {
					this.progressBarPercentage = 0;
					this.showProgressBar = false;
				}, this.progressBarDuration + 500);
			}
		},

		onFileRemove(name) {
			this.form.files = this.form.files.filter(
				(item) => item.name !== name
			);
		},

		onSubmit() {
			if (this.form.files.length > 0) {
				const formData = new FormData();
				this.form.files.forEach(file => {
					formData.append('fileList[]', file);
				});

				this.$emit('submit', formData);
			}
		},
	}
};
</script>

<style>
.progressbar {
	position: relative;
	max-width: 500px;
	width: 100%;
	margin: 30px auto 0;
	height: 30px;
	background: #274545;
	overflow: hidden;
}

span.progress {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 0;
	background: #326666;
	transition: all .3s;
}
</style>