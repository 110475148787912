<template>
  <div class="control_panel">
    <!-- Form -->
    <el-dialog class="brand_voice_dialog" title="New Brand Voice" :visible.sync="showAnalizeForm">
      <BrandVoiceAnalizeTextForm v-if="showAnalizeForm" @nextStep="nextStep" />
    </el-dialog>

    <!-- Create/Edit BV -->
    <el-dialog class="brand_voice_dialog" title="New Brand Voice" :visible.sync="showCreateForm">
      <BrandVoiceCreateForm v-if="showCreateForm" :brandVoice="updatingBrandVoice" @submit="updateOrCreate" />
    </el-dialog>
  </div>
</template>

<script>
import BrandVoiceCreateForm from './BrandVoiceCreateForm.vue';
import BrandVoiceAnalizeTextForm from './BrandVoiceAnalizeTextForm.vue';
import { Dialog } from 'element-ui';

export default {
  components: {
    BrandVoiceAnalizeTextForm,
    BrandVoiceCreateForm,
    [Dialog.name]: Dialog,
  },
  props: {
    updatingBrandVoice: Object
  },
  data() {
    return {
      showAnalizeForm: false,
      showCreateForm: false,
    };
  },
  methods: {
    nextStep() {
      this.showAnalizeForm = false;
      this.showCreateForm = true;
    },

    async updateOrCreate(form) {
      console.log('updateOrCreate', form);
      this.$preloader.fadeIn();

      if (form.id) {
        console.log('updateBrandVoice');
        await this.$store.dispatch('updateBrandVoice', form);
      }
      else {
        console.log('createBrandVoice');
        const response = await this.$store.dispatch('createBrandVoice', form);
        if (response) {
          this.$emit('newBVCreated', response.data?.response?.id);
        }
      }

      this.showCreateForm = false;
      this.$preloader.fadeOut();
    },
  },
  watch: {
    updatingBrandVoice() {
      if (this.updatingBrandVoice) {
        this.nextStep();
      }
    },
    showAnalizeForm() {
      if (!this.showAnalizeForm && !this.showCreateForm) {
        this.$emit('hideForm');
      }
    },
    showCreateForm() {
      if (!this.showCreateForm) {
        this.$emit('hideForm');
      }
    }
  },
  mounted() {
    if (this.updatingBrandVoice) {
      this.nextStep();
    }
    else {
      this.showAnalizeForm = true;
    }
  }
};
</script>
  