<template>
  <div>
    <div>
      <label>{{ $t('tovBrandVoice.moodAndBv') }}</label>
    </div>
    <ValidationProvider :rules="required ? 'required' : ''">
      <el-select v-loading="loading" class="select-primary" size="medium" v-model="brandVoiceId"
        @change="$emit('change', $event)" :disabled="fakeBv">

        <el-option v-if="showAll" class="select-primary" label="All" :value=null></el-option>

        <!-- Create Brand Voice -->
        <el-option v-if="canCreateBrandVoice && !hideCreate && !getUser.extra_info?.hide_brand_voice"
          class="select-primary" :label="$t('tovBrandVoice.createBv')" value="create_brand_voice"></el-option>

        <el-option v-if="!hideTov" class="select-primary" :value="null" label="Neutral">
          <!-- <i class="fa-solid mr-1 fa-star"></i> -->
          <label>Neutral</label>
        </el-option>

        <el-option v-for="brandVoice in activeBrandVoices" :key="brandVoice.id" class="select-primary"
          :value="brandVoice.id" :label="brandVoice.name">
          <i v-if="brandVoice?.type == 'Custom Brand Voice'" class="fa-solid mr-1 fa-star"></i>
          <label>{{ brandVoice.name }}</label>
        </el-option>

        <template v-if="brandVoiceId && !isPreset(brandVoiceId)" slot="prefix">
          <div class="option-prefix">
            <i class='fa-solid fa-star'></i>
          </div>
        </template>
      </el-select>
    </ValidationProvider>

    <BrandVoiceForm v-if="showForm" :key="componentKey" @hideForm="hideForm()" @newBVCreated="setNewBV" />
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';
import { mapGetters } from 'vuex';
import BrandVoiceForm from '@/pages/Pages/ControlPanel/BrandVoice/BrandVoiceForm.vue';

export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    BrandVoiceForm
  },
  data() {
    return {
      loading: false,
      brandVoiceId: null,
      showForm: false,
      componentKey: 0
    }
  },
  props: {
    brandVoiceSelected: {
      type: Number,
      default: null,
      required: false
    },
    fakeBv: {
      type: Boolean,
      default: false,
      required: false
    },
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    hideDefault: {
      type: Boolean,
      default: false,
      required: false
    },
    hideCreate: {
      type: Boolean,
      default: false,
      required: false
    },
    hideTov: {
      type: Boolean,
      default: false,
      required: false
    },
    showAll: {
      type: Boolean,
      default: false,
      required: false
    },
    hideInactiveBv: {
      type: Boolean,
      default: true,
      required: false
    }

  },
  computed: {
    ...mapGetters({
      defaultBrandVoice: 'getDefaultBrandVoice',
      canCreateBrandVoice: 'canCreateBrandVoice',
      getUser: 'getUser',
    }),
    activeBrandVoices() {
      let brandvoices = this.hideInactiveBv ? this.$store.getters.getActiveBrandVoices : this.$store.getters.getBrandVoices;
      if (this.hideTov) {
        return brandvoices.filter(
          (item) => item.type != 'preset'
        );
      }
      return brandvoices;
    }
  },
  watch: {
    brandVoiceId() {
      if (this.brandVoiceId === "create_brand_voice") {
        this.showForm = true;
        this.brandVoiceId = null;
      }
    },
    defaultBrandVoice() {
      if (!this.brandVoiceSelected && !this.hideDefault && this.defaultBrandVoice) {
        this.brandVoiceId = this.defaultBrandVoice.id;
        this.$emit('change', this.brandVoiceId);
      }
    },
    activeBrandVoices() {
      if (this.fakeBv) {
        this.activeBrandVoices.unshift(this.getUser.fake_bv)
        this.brandVoiceId = this.getUser.fake_bv.id;
        this.$emit('change', this.brandVoiceId);
      }
    },
    brandVoiceSelected() {
      this.brandVoiceId = this.brandVoiceSelected;
    },
    showForm() {
      if (this.showForm == false) {
        this.brandVoiceId = null;
        this.$emit('change', this.brandVoiceId);
      }
    },
  },
  methods: {
    hideForm() {
      this.showForm = false;
      this.formComponentKey++;
    },
    setNewBV(brandvoiceId) {
      console.log('change', brandvoiceId)
      this.$emit('change', brandvoiceId);
    },
    isPreset(brandVoiceId) {
      let bv = this.activeBrandVoices.find((item) => item.id == brandVoiceId);
      return bv.type == 'preset';
    }
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch('fetchBrandVoices', this.$store.getters.selectedWorkspace?.id)
      .then(() => this.loading = false);
  }
};
</script>

<style lang="scss">
.el-input__prefix {
  display: flex;
  align-items: center;
  left: 10px !important;
}
</style>
