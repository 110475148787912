<template>
	<ValidationObserver v-slot="{ handleSubmit }">
		<form @submit.prevent="handleSubmit(onSubmit)">
			<div>

				<ValidationProvider v-for="(url, index) in form.urls" :key="index">
					<base-input :label="$t('brandVoices.addByLink.link') + ' ' + (index+1)" type="text"
						:placeholder="$t('brandVoices.addByLink.addLink')" v-model="form.urls[index]">
					</base-input>
				</ValidationProvider>

				<base-button v-show="form.urls.length < maxUrlsNum" @click.prevent="addUrl"
					class="btn-fill btn_submit_form w-100 btn_add_link">
					<i class="fa-solid fa-plus"></i>
					{{ $t('brandVoices.addByLink.addNew') }}
				</base-button>

				<base-button native-type="submit" type="primary" class="btn-fill btn_submit_form w-100">
					{{ $t('brandVoices.addByLink.analyzeLinks') }}
				</base-button>
			</div>
		</form>
	</ValidationObserver>
</template>

<script>
import { Select, Option } from 'element-ui';
export default {
	components: {
		[Select.name]: Select,
		[Option.name]: Option,

	},
	data() {
		return {
			form: {
				urls: [],
			},
			minUrlsNum: 2,
			maxUrlsNum: 7
		};
	},
	methods: {
		resetUrls() {
			for (let index = 0; index < this.minUrlsNum; index++) {
				this.addUrl();
			}
		},
		addUrl() {
			if (this.form.urls.length < this.maxUrlsNum) {
				this.form.urls.push("");
			}
		},
		onSubmit() {
      let urls = this.form.urls.filter(n => n);
      if (urls.length) {
        const form = {
          url: [...urls] // format for the api
        }
        this.$emit('submit', form);
      }
		},
	},
	created() {
		this.resetUrls();
	}
};
</script>
