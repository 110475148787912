<template>
    <div id="audience-manager-tool-container">
        <div class="card border-card flex-grow-1 p-sm">
            <div class="mb-md d-flex">
                <div class="flex-grow-1">
                    <h1 class="card-title bold">Generative AI audience manager</h1>
                    <span>Here you can plan, manage and monitor the performance of your advertising campaigns.</span>
                </div>

                <div id="main-controls">
                    <base-input placeholder="Search..." class="ml-sm" @input="searchCampaigns" v-model="searchQuery" />  

                    <base-button @click="openNewCampaignForm = true">New campaign</base-button>
                </div>
            </div>

            <div class="mb-lg">
                <el-collapse>
                   <el-collapse-item title="Show Filters">
                        <AudienceCampaignFilters
                            :availableClients="existingClients"
                            @change="getCampaignsByFilters"
                        />
                   </el-collapse-item> 
                </el-collapse>
                
            </div>

            <div>
                <el-table :data="campaigns" thead-classes="text-primary">
                    <el-table-column
                        label="Campaign name"
                    >
                        <template #default="{ row }">
                            <span @click="selectedCampaign = row" class="bold" style="cursor:pointer">{{ row.title }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="Advertiser" prop="client.name" />

                    <el-table-column
                        label="Status"
                    >
                        <template #default="{ row }">
                            <el-select v-model="row.status" @change="(newStatus) => updateCampaignStatus(row.id, newStatus)">
                                <el-option
                                    v-for="status in possibleStatuses"
                                    :key="status.value"
                                    :value="status.value"
                                    :label="status.label"
                                />
                            </el-select>
                        </template>
                    </el-table-column>

                    <el-table-column
                        label="Budget"
                    >
                        <template #default="{ row }">
                            <span v-if="row.configuration">{{ formatCost(row.configuration.budget, row.currencyCode) }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        label="Start date"
                    >
                        <template #default="{ row }">
                            <span v-if="row.duration">{{ formatDate(row.duration.startDate) }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        label="Due date"
                    >
                        <template #default="{ row }">
                            <span v-if="row.duration">{{ formatDate(row.duration.endDate) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <div class="card border-card p-sm" id="audience-manager-tool-sidebar" v-if="openForm">
            <div class="d-flex">
                <h2 class="card-title flex-grow-1">Configure campaign</h2>

                <button type="button" class="close" @click="() => {selectedCampaign = null; openNewCampaignForm = false}" data-dismiss="modal"
                    aria-label="Close">
                    <i class="fa-solid fa-circle-xmark"></i>
                </button>
            </div>

            <div class="mt-sm">
                <AudienceCampaignForm
                    isManager
                    canEditCampaign

                    :campaign="selectedCampaign"
                    :availableClients="existingClients"

                    :possibleCampaignTypes="possibleCampaignTypes"
                    :possibleFormats="possibleCampaignFormats"

                    @created-new-client="newClient => existingClients.push(newClient)"
                    @submit="reset"
                />
            </div>
        </div>
    </div>
    
</template>

<script>
import { Table, TableColumn, DatePicker, Select, Option, Collapse, CollapseItem } from "element-ui"
import { BaseInput, BaseButton } from "@/components"
import AudienceCampaignFilters from "../../Components/AudienceCampaignFilters.vue"
import AudienceCampaignForm from "../../Components/AudienceCampaignForm.vue"

import AudienceCampaign from '../../Entities/AudienceCampaign'
import AudienceCampaignClient from "../../Entities/AudienceCampaignClient"
import { formatCost, formatStatus, formatDate } from '../../Helpers/formatters'
import { apiHandler } from "@/util/errorHandling";
import debounce from 'lodash/debounce'


export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [DatePicker.name]: DatePicker,
        [Select.name]: Select,
        [Option.name]: Option,
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
        BaseInput,
        BaseButton,
        AudienceCampaignFilters,
        AudienceCampaignForm,
    },
    data() {
        return {
            /** @type AudienceCampaign[] */
            campaigns: [],
            /** @type AudienceCampaign|null */
            selectedCampaign: null,
            /** @type AudienceCampaignClient[] */
            existingClients: [],
            possibleStatuses: [],
            possibleCampaignTypes: [],
            possibleCampaignFormats: [],
            openNewCampaignForm: false,
            // This variable is not used, however if we don't put SOMETHING as a v-model on the
            // base-input component it won't work, so this is what this is used for
            searchQuery: null,
        }
    },
    computed: {
        openForm() {
            return this.selectedCampaign || this.openNewCampaignForm
        }
    },
    methods: {
        async getSetupInformation () {
            let response;

            await apiHandler(async () => {
                response = await this.$axios.get('tools/audience-campaigns/manager/get-setup-information')
            })

            const options = response.data.options
            this.possibleStatuses = options.available_campaign_statuses
            this.possibleCampaignTypes = options.available_campaign_types
            this.possibleCampaignFormats = options.available_campaign_configuration_formats
            this.$forceUpdate()
        },
        async getCampaigns() {
            let response;

            await apiHandler(async () => {
                response = await this.$axios.get('tools/audience-campaigns/manager/get-campaigns')
            })

            this.campaigns = response.data.data.map(c => new AudienceCampaign(c))
            this.$forceUpdate()
        },
        async getCampaignsByFilters(filters) {
            let response;

            await apiHandler(async () => {
                response = await this.$axios.get('tools/audience-campaigns/manager/get-campaigns-by-filters', {
                    params: {
                        'title': filters.title,
                        'client_id': filters.clientId,
                        'start_date': filters.startDate,
                        'end_date': filters.endDate,
                    }
                })
            })

            this.campaigns = response.data.data.map(c => new AudienceCampaign(c))
            this.$forceUpdate()
        },
        searchCampaigns: debounce(async function (query) {
            await this.getCampaignsByFilters({
                title: query,
            })
        }, 500),
        async getAllClients() {
            let response;

            await apiHandler(async () => {
                response = await this.$axios.get('tools/audience-campaigns/manager/get-all-clients')
            })

            this.existingClients = response.data.data.map(c => new AudienceCampaignClient(c))
            this.$forceUpdate()
        },
        async updateCampaignStatus(campaignId, newStatus) {
            const payload = {
                'campaign_id': campaignId,
                'status': newStatus,
            }

            await apiHandler(async () => {
                await this.$axios.patch('tools/audience-campaigns/manager/update-campaign-status', payload)
            })
        },
        async reset() {
            this.selectedCampaign = null
            this.openNewCampaignForm = false
            await this.getCampaigns()
            await this.getAllClients()

            this.$forceUpdate()
        },
        formatStatus,
        formatCost,
        formatDate,
    },
    async mounted() {
        await this.getSetupInformation()
        await this.reset()
    }
}
</script>

<style scoped lang="scss">
#audience-manager-tool-container {
    display: flex;
    gap: 22px;
    align-items: flex-start;

    #main-controls {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--gap, 10px);

        > * {
            margin: 0 !important;
        }
    }
}

#audience-manager-tool-sidebar {
    max-width: 400px;
}

#audience-manager-tool-step-selection {
    display: flex;
    gap: var(--gap, 10px);
    align-items: stretch;

    button {
        display: block;

        flex-grow: 1;

        border: 1px solid var(--blu-004583, #004583);
        background-color: var(--white-ffffff, #FFFFFF);
        color: var(--blu-004583, #004583);
        padding: 10px 20px;
        border-radius: 1000px;

        &.selected {
            background-color: var(--blu-004583, #004583);
            color: var(--white-ffffff, #FFFFFF);
        }
    }
}

.status-chip {
    &-to-do {
        background-color: #999999 !important;
    }

    &-in-progress {
        background-color: #FF9D00 !important;
    }

    &-done {
        background-color: #00BC7E !important;
    }
}
</style>