/**
 * @typedef AudienceCampaignConfigurationType
 * @property {string|null} targetAudience
 * @property {Number|null} budget
 * @property {Number|null} cpm
 * @property {string[]|null} formats
 * @property {string|null} additionalFormats
 * @property {Number|null} postClickTracking
 * @property {Number|null} postViewTracking
 * @property {string|null} notes
 * @property {{adServer: bool, googleAnalytics: bool}} uses
 */

/** @type AudienceCampaignConfigurationType */
export default class AudienceCampaignConfiguration {
    constructor(configurationJson) {
        this.targetAudience = configurationJson.target_audience
        this.budget = configurationJson.budget
        this.cpm = configurationJson.cpm
        this.formats = configurationJson.formats
        this.additionalFormats = configurationJson.additional_formats
        this.postClickTracking = configurationJson.post_click_tracking
        this.postViewTracking = configurationJson.post_view_tracking
        this.notes = configurationJson.notes
        this.uses = {
            adServer: configurationJson.uses.ad_server ?? false,
            googleAnalytics: configurationJson.uses.google_analytics ?? false,
        }
    }
}