<template>
    <div>
        <form @submit.prevent class="d-flex flex-row" id="audience-manager-filters-container">
            <div>
                <label class="base-input__label">Date range</label>
                <el-date-picker
                  v-model="selectedCampaignDurationIntermediate"
                  type="daterange"
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                  clearable
                />
            </div>

            <div>
                <label class="base-input__label">Advertiser</label>
                <el-autocomplete
                    v-model="clientName"
                    :fetch-suggestions="searchForClients"
                    @select="(item) => filters.clientId = item.client.id"
                    @change="() =>  filters.clientId = null"
                    clearable
                />
            </div>
        </form>
    </div>
</template>

<script>
import AudienceCampaignClient from '../Entities/AudienceCampaignClient';

import { DatePicker, Autocomplete } from "element-ui"
import { formatDate } from '../Helpers/formatters';


export default {
    components: {
        [DatePicker.name]: DatePicker,
        [Autocomplete.name]: Autocomplete,
    },
    props: {
        /** @type {AudienceCampaignClient[]} */
        availableClients: {
            type: Array,
            required: false,
            default: () => [],
        }
    },
    data() {
        return {
            filters: {
                clientId: null,
                startDate: null,
                endDate: null,
            },
            // This variable is not used, however if we don't put SOMETHING as a v-model on the
            // autocomplete component it won't work, so this is what this is used for
            clientName: null,
            // The datepicker component uses a different format than our object,
            // this is an intermediate variable used to handle conversions
            selectedCampaignDurationIntermediate: [],
        }
    },
    methods: {
        formatDate,
        /**
         * @param {string} queryString
         * @param {Function} resultCallback 
         */
        searchForClients(queryString, resultCallback) {
            const results = this.availableClients
                .filter(client => {
                    if (!queryString || queryString.trim().length === 0) {
                        return true
                    }
                    return client.name.toLowerCase().indexOf(queryString.toLowerCase()) >= 0
                })
                .map(client => {
                    return {
                        value: client.name,
                        client
                    }
                })
            resultCallback(results)
        },
    },
    watch: {
        selectedCampaignDurationIntermediate: {
            handler(newValue) {
                if (newValue) {
                    this.filters.startDate = formatDate(newValue[0])
                    this.filters.endDate = formatDate(newValue[1])
                } else {
                    this.filters.startDate = null
                    this.filters.endDate = null
                }
            }
        },
        filters: {
            deep: true,
            handler (newFilters) {
                this.$emit('change', newFilters)
            }
        }
    }
}
</script>

<style scoped>
.base-input__label {
    display: block !important;
}

#audience-manager-filters-container {
    gap: var(--gap, 10px);
}
</style>
